import React, { Component } from 'react'
import Footer from '../components/Footer/Footer'
import LeadForm from '../components/Forms/LeadForm'
import Link from 'gatsby-link'
import classes from '../layouts/Pages.module.css'
import ArrowDown from '../images/arrow-down.png'
import MobileActions from '../components/MobileActions/MobileActions'
import Helmet from 'react-helmet'
import Layout from '../layouts'

export default class extends Component {
    
    render() {
        return (
            <Layout location={this.props.location}>
                <Helmet>
                    <title>Foire aux questions | Psy-Scan Institute | 032.721.49.33</title>
                    <meta name="description" content="Nos consultations se déroulent du Lundi au Vendredi de 9h à 12h et 13h à 17h. Vous pouvez nous appelez ou réserver votre consultation en ligne."/>
                </Helmet>
                <div>
                    <div style={{maxWidth: 1170, margin: 'auto', padding: '40px 15px 60px'}}>
                        <h1>Foire aux Questions</h1>
                        <div className={classes.faqContainer}>
                            <div className={classes.faqContentWrapper}>
                                <Faq question="J’ai besoin de voir quelqu’un en urgence!">
                                    Si vous êtes en situation de crise ou une personne est en danger, n'utilisez pas ce site. Appelez le 032 755 15 15 ou <a href="https://www.cnp.ch/patients-et-entourage/urgences/" target="_blank" rel="noreferrer">utilisez cette ressource</a> pour obtenir une aide immédiate.
                                </Faq>
                                <Faq question="Je suis étranger et ne dispose pas de l’assurance de base (LAMal). Puis-je bénéficier de vos services ?">
                                    Vous pouvez bénéficier de nos services au même titre qu’un Suisse. En revanche, vous devrez acquitter les frais de consultation après la séance. Nous vous remettrons une facture pour vos assurances.
                                </Faq>
                                <Faq question="Est-ce que les psychologues sont pris en charge par la LAMal?">
                                    Les séances avec un psychologue sont prises en charge par l’assurance de base (LAMal), moins la franchise et la quote-part (selon votre police d’assurance).
                                </Faq>

                                <Faq question="Que dois-je faire pour obtenir un certificat médical?">
                                    Votre demande peut être adressée à votre médecin du Psy-Scan Institute.
                                </Faq>
                                <Faq question="Est-ce que mon thérapeute peut obtenir mon dossier du CNP?">
                                    Votre thérapeute peut obtenir votre dossier médical du CNP uniquement avec votre consentement.
                                </Faq>
                                <Faq question="J’ai des effets secondaires avec ma médication, que faire?">
                                    Vous pouvez <Link to="/contact">contacter le Psy-Scan</Link> et demander à parler à votre médecin pour lui faire part des effets secondaires observés.
                                </Faq>
                                <Faq question="Que faire si je ne m’entends pas avec mon thérapeute?">
                                    Veuillez contacter le <Link to="/contact">Service des admissions</Link> pour leur faire part de la situation.
                                </Faq>
                                <Faq question="Est-ce possible d’annuler un rendez-vous?">
                                    En cas d’empêchement, vous pouvez annuler votre rendez-vous en prenant contact avec le <Link to="/contact">Psy-Scan Insitute</Link>. S’il est annulé 48h à l’avance, il ne sera pas facturé. Au-delà, le thérapeute pourrait facturer la somme de CHF 50.
                                </Faq>
                                <Faq question="Puis-je parler à mon thérapeute par téléphone?">
                                    Vous pouvez <Link to="/contact">prendre contact</Link> avec le Psy-Scan Institute et demander à parler à votre thérapeute. S’il est disponible, nous lui transmettrons l’appel.
                                </Faq>
                                <Faq question="Qui s’occupe de mes prochains rendez-vous?">
                                    Les prochains rendez-vous sont organisés avec votre thérapeute lors d’une séance. En cas de besoin, vous pouvez appeler le 032 721 49 33 pour prendre un nouveau rendez-vous.
                                </Faq>
                                <Faq question="À quelle fréquence sont fixés les rendez-vous?">
                                    La fréquence des rendez-vous est déterminée par votre thérapeute suivant la situation.
                                </Faq>
                                <Faq question="Combien de temps dure une séance?">
                                    Une séance dure environ 1h, excepté le premier rendez-vous qui est d’environ 1h30.
                                </Faq>
                            </div>
                            <div className={classes.faqFormWrapper}>
                                <LeadForm/>
                            </div>
                        </div>
                    </div>
                    {this.props.scrollPosition > 100
                        ? <MobileActions/>
                        : null
                    }
                </div>
                <Footer/>
            </Layout>
        )
    }

}

class Faq extends Component {
    state = {
        open: false
    }

    showResponse = () => this.setState({ open: !this.state.open})
    render() {
        const { open } = this.state
        return (
            <div className={classes.faqItemWrapper} style={this.props.style}>
                <div className={classes.faqItemTitleWrapper} onClick={this.showResponse}>
                    <div className={classes.faqItemTitle}>{this.props.question}</div>
                    <div className={open ? [classes.faqItemIcon, classes.Active].join(' ') : classes.faqItemIcon}>
                        <img src={ArrowDown} alt="Ouvrir la réponse" style={{width: '100%'}}/>
                    </div>
                </div>

                { open
                    ? <div className={classes.faqItemContent}>
                        {this.props.children}
                    </div>
                    : null
                }
            </div>
        )
    }
}